export default {
  purchaseStaticsTableColumns: [
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: 160
    },
    {
      label: '销售渠道',
      prop: 'saleChannelName',
      width: 220,
      overflow: true
    },
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'bgName' },
    { label: 'BU', prop: 'buName' },
    { label: '产品类目', prop: 'productCategoryName',
      formatter: (row) => {
        return JSON.parse(row.productCategoryName)?.join('>')
      }
    },
    { label: '商品类目', prop: 'categoryName',
      formatter: (row) => {
        return JSON.parse(row.categoryName)?.join('>')
      }
    },

    { label: '季节', prop: 'seasonName' },
    { label: '开款季', prop: 'launchSeasonDictValue' },
    { label: '产品定位', prop: 'positionName' },
    { label: 'Style', prop: 'styleName', width: 120 },
    { label: 'Color', prop: 'colorName', width: 120 },
    { label: 'Size', prop: 'sizeName' },
    { label: 'SKU', prop: 'skuName', width: 240 },
    { label: '年份', prop: 'demandDateYear' },
    { label: '月份', prop: 'demandDateMonth' },
    { label: '周', prop: 'demandDateWeek' },
    { label: '需求日期', prop: 'demandDate', width: 160 },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品类型', prop: 'productType' },
    { label: '计划负责人', prop: 'planOperatorName' },
    { label: '净需求数量', prop: 'netDemandQty' },
    { label: '快船翻单Turnover', prop: 'fastTurnover' },
    { label: '快船预计翻单数量', prop: 'fastReorderQty' },
    { label: '普船翻单Turnover', prop: 'normalTurnover' },
    { label: '普船预计翻单数量', prop: 'normalReorderQty' },
    { label: '收货仓', prop: 'receiptWarehouseName' },
    { label: '实际翻单数量', prop: 'actualReorderQty' },
    { label: '版本', prop: 'versionDateMonth' }

  ],
  allotStaticsTableColumns: [
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: 160
    },
    {
      label: '销售渠道',
      prop: 'platSiteShopName',
      width: 200
    },
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'bgName' },
    { label: 'BU', prop: 'buName' },
    { label: '产品类目', prop: 'productCategoryFullName' },
    { label: '商品类目', prop: 'categoryFullName' },
    { label: '适用季节', prop: 'seasonName' },
    { label: '上市季节', prop: 'launchSeasonDictValue' },
    { label: '产品定位', prop: 'positionName' },
    { label: 'Style', prop: 'styleName' },
    { label: 'Color', prop: 'colorName' },
    { label: 'Size', prop: 'sizeName' },
    { label: 'SKU', prop: 'skuName', width: 260 },
    { label: '年份', prop: 'year' },
    { label: '月份', prop: 'month' },
    { label: '周', prop: 'demandDateWeekName' },
    { label: '需求日期', prop: 'demandDate', width: 160 },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品类型', prop: 'productType' },
    { label: '计划负责人', prop: 'planOperatorName' },
    { label: '国内补货净需求数量', prop: 'cnNetDemandQtyDisplay' },
    { label: '海外补货净需求数量', prop: 'overseaNetDemandQtyDisplay' },
    { label: '海外补货Turnover', prop: 'overseasReplTurnoverDisplay' },
    { label: '海外预计发运数量', prop: 'overseasPredictShipQtyDisplay' },
    { label: '快船补货Turnover', prop: 'cnFastReplTurnoverDisplay' },
    { label: '快船预计发运数量', prop: 'cnFastPredictShipQtyDisplay' },
    { label: '普船补货Turnover', prop: 'cnGenReplTurnoverDisplay' },
    { label: '普船预计发运数量', prop: 'cnGenPredictShipQtyDisplay' },
    { label: '调出仓', prop: 'outWarehouseName' },
    { label: '调入仓', prop: 'inWarehouseName' },
    { label: '预计发运数量（海外仓）', prop: 'detailOverseasPredictShipQtyDisplay' },
    { label: '预计发运数量（国内快船）', prop: 'detailCnFastPredictShipQtyDisplay' },
    { label: '预计发运数量（国内普船）', prop: 'detailCnGenPredictShipQtyDisplay' },
    { label: '实际发运数量', prop: 'detailActualShipQtyDisplay' },
    { label: '版本', prop: 'version' }

  ],
  salesStaticsTableColumns: [
    {
      label: '销售渠道',
      prop: 'platSiteShopName',
      formatter: (row) => {
        return row.platformName && `${row.platformName}/${row.siteName}/${row.shopName}`
      }
    },
    {
      prop: 'stockChannelName',
      label: '备货渠道',
      width: 150
    },
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'brandName' },
    { label: 'BU', prop: 'buName' },
    { label: '产品类目', prop: 'productCategoryName',
      formatter: (row) => {
        return JSON.parse(row.productCategoryName)?.join('>')
      }
    },
    { label: '商品类目', prop: 'categoryName',
      formatter: (row) => {
        return JSON.parse(row.categoryName)?.join('>')
      }
    },
    { label: '适用季节', prop: 'seasonName' },
    { label: '上市季节', prop: 'launchSeasonDictValue' },
    { label: '产品定位', prop: 'positionName' },
    { label: 'Style', prop: 'styleName' },
    { label: 'Color', prop: 'color' },
    { label: 'Size', prop: 'size' },
    { label: 'SKU', prop: 'skuName', width: 240 },
    { label: '年份', prop: 'fcstYear' },
    { label: '月份', prop: 'fcstDate', formatter: row => {
      return row.fcstDate?.split('-')[1]
    } },
    { label: '周', prop: 'fcstWeek' },
    { label: '销售日期', prop: 'fcstDate' },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品类型', prop: 'productType' },
    { label: 'Operator', prop: 'operatorName' },
    { label: '预测销量', prop: 'daySale' },
    { label: '预测退货', prop: 'dayReturn' },
    { label: '销售数量', prop: 'dayRealSale' },
    { label: '退货数量', prop: 'dayRealReturn' }
  ],
  // 全渠道库存周转分析
  inventoryStatsAnalyzeTableColumns: [
    { label: 'BU', prop: 'buName', width: 120 },
    { prop: 'stockChannelName', label: '备货渠道', width: 100 },
    { label: '适用季节', prop: 'seasonName', width: 90 },
    { label: '新老品标识', prop: 'productSymbolValue', width: 90 },
    { label: '产品类型标识', prop: 'productTypeValue', width: 90 },
    { label: '产品定位', prop: 'positionName', width: 90 },
    { label: '平台在仓', prop: 'platformQty', width: 100 },
    { label: '平台在途', prop: 'platformOnwayQty' },
    { label: '海外三方在仓', prop: 'abroadThirdQty' },
    { label: '海外三方在途', prop: 'abroadThirdOnwayQty' },
    { label: '海外自管在仓', prop: 'abroadOwnQty' },
    { label: '海外自管在途', prop: 'abroadOwnOnwayQty' },
    { label: '国内在仓', prop: 'internalQty' },
    { label: '国内在途', prop: 'internalOnWayQty' },
    { label: '采购未结', prop: 'purchaseOnwayQty' },
    { label: '总库存', prop: 'totalQty' },
    { label: '计总库存', prop: 'allQty' },
    { label: 'RPS 7', prop: 'rps7' },
    { label: 'RPS 30', prop: 'rps30' },
    { label: '平台在仓RPS周转天数', prop: 'platformRps' },
    { label: '平台在仓MPS周转天数', prop: 'platformMps' },
    { label: '平台在途RPS周转天数', prop: 'platformOnwayRps' },
    { label: '平台在途MPS周转天数', prop: 'platformOnwayMps' },
    { label: '海外三方在仓RPS周转天数', prop: 'abroadThirdRps' },
    { label: '海外三方在仓MPS周转天数', prop: 'abroadThirdMps' },
    { label: '海外三方在途RPS周转天数', prop: 'abroadThirdOnwayRps' },
    { label: '海外三方在途MPS周转天数', prop: 'abroadThirdOnwayMps' },
    { label: '海外自管在仓RPS周转天数', prop: 'abroadOwnRps' },
    { label: '海外自管在仓MPS周转天数', prop: 'abroadOwnMps' },
    { label: '海外自管在途RPS周转天数', prop: 'abroadOwnOnwayRps' },
    { label: '海外自管在途MPS周转天数', prop: 'abroadOwnOnwayMps' },
    { label: '国内在仓RPS周转天数', prop: 'internalRps' },
    { label: '国内在仓MPS周转天数', prop: 'internalMps' },
    { label: '国内在途RPS周转天数', prop: 'internalOnWayRps' },
    { label: '国内在途MPS周转天数', prop: 'internalOnWayMps' },
    { label: '采购未结RPS周转天数', prop: 'purchaseOnwayRps' },
    { label: '采购未结MPS周转天数', prop: 'purchaseOnwayMps' },
    { label: '总库存RPS周转天数', prop: 'totalRps' },
    { label: '总库存MPS周转天数', prop: 'totalMps' },
    { label: '计总库存RPS周转天数', prop: 'allRps' },
    { label: '计总库存MPS周转天数', prop: 'allMps' }
  ],
  // 供应及缺货分析
  commoditySupplyAndShortageStatistics: [
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'bg' },
    { label: 'BU', prop: 'buName' },
    { label: '产品类目', prop: 'productCategoryName',
      width: 130,
      formatter: (row) => {
        return row.productCategoryName && JSON.parse(row.productCategoryName).join('>')
      }
    },
    { label: '商品类目', prop: 'categoryName',
      width: 140,
      formatter: (row) => {
        return row.categoryName && JSON.parse(row.categoryName).join('>')
      }
    },
    { label: '适用季节', prop: 'seasonName' },
    { label: '上市季节', prop: 'launchSeasonDictValue' },
    { label: '产品定位', prop: 'positionName' },
    {
      label: '备货渠道',
      prop: 'stockChannelName',
      formatter: (row) => {
        return row.stockChannelName || '-'
      }
    },
    {
      label: '销售渠道',
      prop: 'saleChannelName',
      formatter: (row) => {
        return row.saleChannelName || '-'
      },
      width: 200,
      overflow: true
    },
    { label: 'Style', prop: 'styleName' },
    { label: 'Color', prop: 'color' },
    { label: 'Size', prop: 'size' },
    { label: 'SKU', prop: 'skuName', width: 280 },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品类型标识', prop: 'productType' },
    { label: '弃置产品标识', prop: 'dispose' },
    { label: '年份', prop: 'fcstYear',
      formatter: (row) => {
        return row.demandDate?.split('-')[0]
      } },
    { label: '月份', prop: 'fcstDate',
      formatter: (row) => {
        return row.demandDate?.split('-')[1]
      }
    },
    { label: '周', prop: 'demandDateWeekStr' },
    { label: '销售日期', prop: 'demandDate' },
    { label: '平台可用库存', prop: 'platAvlQty' },
    { label: '快船供应数量', prop: 'fastToQty' },
    { label: '普船供应数量', prop: 'genToQty' },
    { label: '预测销量', prop: 'predictSalesQty' },
    { label: '实际销量', prop: 'actualSalesQty' },
    { label: '退货率(%)', prop: 'returnRate' },
    { label: '预测退货', prop: 'returnQty' },
    { label: '前一天的快船供应数量', prop: 'yesterdayFastToQty' },
    { label: '前一天的普船供应数量', prop: 'yesterdayGenToQty' },
    { label: '快船库存缺口', prop: 'fastStockGap' },
    { label: '普船库存缺口', prop: 'genStockGap' },
    { label: '缺货原因', prop: 'causeNameStr' },
    { label: '生成时间', prop: 'createTime' },
    { label: '更新时间', prop: 'modifyTime' },
    { label: '操作人', prop: 'createByName' }

  ],
  inventoryStaticsTableColumns: [
    {
      prop: 'warehouseName',
      label: '仓库'
    },
    {
      prop: 'channelName',
      label: '备货渠道',
      width: 150

    },
    {
      label: '销售渠道',
      prop: 'platformSiteShop',
      width: 200,
      overflow: true
    },
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'brandName' },
    { label: 'BU', prop: 'buName' },
    { label: '产品类目', prop: 'productCategoryNameList' },
    { label: '商品类目', prop: 'categoryNameList',
      formatter: row => {
        return row.categoryNameList?.join('>')
      }
    },
    { label: '适用季节', prop: 'seasonName' },
    { label: '上市季节', prop: 'launchSeasonDictValue' },
    { label: '产品定位', prop: 'positionName' },
    { label: 'Style', prop: 'styleName' },
    { label: 'Color', prop: 'colorName' },
    { label: 'Size', prop: 'sizeName' },
    { label: 'SKU', prop: 'sku', width: 280 },
    { label: '年份', prop: 'year' },
    { label: '月份', prop: 'month' },
    { label: '周', prop: 'week' },
    { label: '库存日期', prop: 'invDate', width: 100 },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品类型', prop: 'productTypeValue' },
    { label: '总库存', prop: 'totalQty' },
    { label: '可用库存', prop: 'availableQty' },
    { label: '可补库存', prop: 'replenishQty' },
    { label: '可售库存', prop: 'sellableQty' },
    { label: '锁定库存', prop: 'lockTotalQty' },
    { label: '未发锁定', prop: 'lockQty' },
    { label: '库内锁定', prop: 'innerLockQty' },
    { label: '残品库存', prop: 'damagedQty' },
    { label: '调拨在途', prop: 'transferOnWayQty' },
    { label: '采购在途', prop: 'purchaseOnWayQty' },
    { label: '计总库存', prop: 'allInvQty' },
    { label: '库存版本', prop: 'invVersion', width: 100 }
  ],
  SalesPlanStatisticsTableColumns: [
    { prop: 'channelName', label: '备货渠道' },
    { label: '销售渠道', prop: 'saleChannel' },
    { label: '品牌', prop: 'brandName' },
    { label: 'BG', prop: 'bgName' },
    { label: 'BU', prop: 'buName' },
    { label: '新老品标识', prop: 'productSymbolValue' },
    { label: '产品定位', prop: 'positionName' },
    { label: 'Style', prop: 'styleName' },
    { label: 'Color', prop: 'colorName' },
    { label: 'Size', prop: 'sizeName' },
    { label: 'SKU', prop: 'skuName' },
    { label: '年份', prop: 'planMonth' },
    { label: '月份', prop: 'planMonth' },
    { label: '实际销量', prop: 'actualSales' },
    { label: 'N-4月版预测销量', prop: 'predictSale4' },
    { label: 'N-3月版预测销量', prop: 'predictSale3' },
    { label: 'N-2月版预测销量', prop: 'predictSale2' },
    { label: 'N-1月版预测销量', prop: 'predictSale1' },
    { label: 'N-4月版预测准确率', prop: 'predictAccuracy4' },
    { label: 'N-3月版预测准确率', prop: 'predictAccuracy3' },
    { label: 'N-2月版预测准确率', prop: 'predictAccuracy2' },
    { label: 'N-1月版预测准确率', prop: 'predictAccuracy1' },
    { label: '预测准确率', prop: 'predictAccuracy' },
    { label: 'BG预测准确率', prop: 'predictAccuracyBg' },
    { label: 'BU预测准确率', prop: 'predictAccuracyBu' },
    { label: '备货渠道预测准确率', prop: 'predictAccuracyChannel' },
    { label: '公司预测准确率', prop: 'predictAccuracyCompany' },
    { label: '新老品预测准确率', prop: 'predictAccuracySymbol' },
    { label: '产品定位预测准确率', prop: 'predictAccuracyPosition' },
    { label: '产品类型预测准确率', prop: 'predictAccuracyProductType' },
    { label: '预测偏差量', prop: 'predictDeviation' },
    { label: '预测偏差率', prop: 'predictDeviationRate' },
    { label: '预测波动率', prop: 'predictVolatility' },
    { label: '新老品&BG预测准确率', prop: 'predictAccuracyBgSymbol' },
    { label: '新老品&BU预测准确率', prop: 'predictAccuracyBuSymbol' },
    { label: '新老品&备货渠道预测准确率', prop: 'predictAccuracyChannelSymbol' },
    { label: 'BU+备货渠道', prop: 'predictAccuracyChannelBu' },
    { label: '生成时间', prop: 'createTime' },
    { label: '创建人', prop: 'createByName' },
    { label: '更新时间', prop: 'modifyTime' },
    { label: '操作人', prop: 'modifyByName' }
  ]
}
