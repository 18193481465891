
import staticsTablecolumns from './modules/statics-analysis'

export default staticsTablecolumns

export const paymentDetailsColumns = [
  { slot: 'settletype' },
  { label: '来源单据', prop: 'purchaseSettlementCode' },
  { label: '结算金额', prop: 'settlementAmount' },
  { label: '待请款金额', prop: 'waitRequestFundsAmount' },
  { label: '请款金额', prop: 'requestFundsAmount' },
  { slot: 'currency' },
  { slot: 'dAmount' },
  { label: '已付款金额', prop: 'paymentPrice' },
  { slot: 'frozenState' }
]

export const paymentColumns1 = [
  { label: '其中', prop: 'includes', width: 160 },
  { label: '开票金额', prop: 'invoiceAmount' },
  { label: '未税金额', prop: 'noTaxAmount' },
  { label: '增值税额', prop: 'valueAddedTaxAmount' },
  { slot: 'same' }
]

export const purchasePlanTableColumns = [
  { label: '采购计划单号', prop: 'purchasePlanCode', width: 180 },
  { label: '状态', prop: 'purchaseStatusI18', width: 100 },
  { label: 'BU', prop: 'buName' },
  { label: '平台', prop: 'platformName' },
  { label: '站点', prop: 'siteCode' },
  { label: 'Style', prop: 'styleName', width: 100 },
  { label: '季节', prop: 'seasonI18' },
  { slot: 'sku' },
  { slot: 'color' },
  { slot: 'size' },
  { label: '预计交货日期', prop: 'expectDeliveryDate', width: 160 },
  { label: '数量', prop: 'number' },
  { label: '已转订单数量', prop: 'orderNumber' },
  { label: '未转订单数量', prop: 'noOrderNumber' },
  { label: '创建时间', prop: 'createDate', width: 120 },
  { label: '创建人', prop: 'createByName', width: 130 }
]

export const purchaseOrderTableColumns = [
  { label: '采购订单号', prop: 'purchaseOrderCode', width: 180 },
  { label: '采购计划单号', prop: 'purchasePlanCode', width: 180 },
  { label: '订单状态', prop: 'orderStatusI18', width: 100 },
  { label: '质检状态', prop: 'qualityStatusI18', width: 100 },
  { label: '供应商', prop: 'vendorName', width: 180 },
  { label: '预计交货日期', prop: 'predictDeliveryDate', width: 160 },
  { label: '计划评审到货日期', prop: 'planDeliveryDate', width: 160 },
  { label: '采购反馈到货日期', prop: 'vendorDeliveryFeedback', width: 160 },
  { slot: 'delay' },
  { label: '采购反馈到货日期备注', prop: 'vendorDeliveryFeedbackRemark', width: 100 },
  { label: '采购员', prop: 'purchasePersonnel', width: 160 },
  { label: 'Style', prop: 'style', width: 100 },
  { slot: 'color' },
  { slot: 'size' },
  { label: '季节', prop: 'seasonI18' },
  { slot: 'sku' },
  { label: '平台站点', prop: 'platformSite' },
  { label: '采购总数量', prop: 'totalNumber', width: 100 },
  { label: '收货数量', prop: 'collectNumber' },
  { label: '退货数量', prop: 'returnNumber' },
  { label: '剩余未收货数量', prop: 'unreceivedNumber', width: 100 },
  { label: '未结数量', prop: 'unclearedPairs', width: 100 },
  { label: '创建人', prop: 'createByName', width: 130 },
  { label: '创建日期', prop: 'createDate', width: 120 },
  { label: '下单日期', prop: 'produceProgressDate', width: 120 }
]

export const columns1 = [
  { label: '暂估应付单', prop: 'estimationPayableCode' },
  { label: '采购单', prop: 'purchaseOrderCode' },
  { label: '来源单据', prop: 'sourceCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '单据金额', prop: 'totalPrice' },
  { label: '付款比例%', prop: 'paymentRatio' },
  { label: '付款金额', prop: 'paymentAmount' },
  { label: '未税金额', prop: 'noTaxAmount' },
  { label: '增税金额', prop: 'valueAddedTaxAmount' },
  { label: '已冲抵', prop: 'haveOffsetAmount' },
  { label: '已收款', prop: 'haveCollectAmount' },
  { slot: 'invoice' },
  { label: '开票金额', prop: 'invoiceAmount' },
  { label: '不开票金额', prop: 'noInvoiceAmount' },
  { label: '仓库', prop: 'warehouseName' },
  { label: '对账确认日期', prop: 'reconcileConfirmTime' }
]
export const columns2 = [
  { label: '其中', prop: 'includes', width: '160px' },
  { label: '开票金额', prop: 'invoiceAmount', width: '130px' },
  { label: '未税金额', prop: 'noTaxAmount', width: '130px' },
  { label: '增值税额', prop: 'valueAddedTaxAmount', width: '130px' }
]
export const columns3 = [
  { label: '请款单', prop: 'requestFundsCode', width: '170' },
  { label: '请款时间', prop: 'createTime' },
  { label: '请款金额', prop: 'requestFundsAmount', width: 120 },
  { label: '请款人', prop: 'createByName' },
  { label: '请款审批状态', prop: 'requestFundsApproveStatusI18' },
  { label: '请款审批时间', prop: 'requestFundsApproveDate' },
  { label: '付款状态', prop: 'paymentStatusI18' },
  { label: '付款时间', prop: 'paymentTime' }

]
export const columns4 = [
  { label: '采购单', prop: 'purchaseOrderCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'purchasePrice' },
  { label: '数量', prop: 'purchasePairs' },
  { label: '金额合计', prop: 'purchaseTotalAmount', width: 120 },
  { label: '备注', prop: 'remark' }
]

export const regColumns = [
  { slot: 'ticket' },
  { label: '发票未税金额', prop: 'invoiceNoTaxAmount' },
  { label: '发票税额', prop: 'invoiceTaxAmount' },
  { label: '税率%', prop: 'taxRate' },
  { label: '开票日期', prop: 'invoiceDate' },
  { label: '登记日期', prop: 'createTime' },
  { label: '登记人', prop: 'createByName' }
]

export const sourceCodeColumns1 = [
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'unitPrice' },
  { label: '调整后单价', prop: 'changeAfterHaveTaxPrice', width: 120 },
  { label: '数量', prop: 'collectPairs' },
  { label: '对账数量', prop: 'reconcilePairs', width: 110 },
  { label: '金额合计', prop: 'amount', width: 110 },
  { label: '对账金额合计', prop: 'reconcileAmount', width: 140 },
  { label: '备注', prop: 'modifyPriceRemark' }
]

export const sourceCodeColumns2 = [
  { label: '退货单号', prop: 'returnCode' },
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: '收货单号', prop: 'collectCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '单价', prop: 'unitPrice' },
  { label: '数量', prop: 'returnPairs' },
  { label: '金额合计', prop: 'returnAmount', width: 120 },
  { label: '备注', prop: 'modifyPriceRemark' }
]

export const sourceCodeColumns3 = [
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: 'Style', prop: 'style' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '采购单价', prop: 'purchasePrice' },
  { label: '采购数量', prop: 'number' },
  { label: '剩余数量', prop: 'surplusPairs', width: 100 },
  { label: '采购金额', prop: 'purchaseTotalPrice', width: 120 },
  { label: '备注', prop: 'remark' }
]

// 请款弹框
export const vendorColumns = [
  { slot: 'selection' },
  { label: '结算类型', prop: 'settlementTypeI18' },
  { label: '采购结算单', prop: 'purchaseSettlementCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '业务单据', prop: 'sourceCode' },
  { label: '采购订单', prop: 'purchaseOrderCode' },
  { label: '税率', prop: 'taxRatio' },
  { label: '结算金额', prop: 'settlementAmount', width: 120 },
  { label: '待冲抵金额', prop: 'waitRequestFundsAmount', width: 110 },
  { slot: 'invoice' },
  { label: '开票金额', prop: 'invoiceAmount', width: 110 },
  { label: '不开票金额', prop: 'noInvoiceAmount', width: 110 },
  { label: '币种', prop: 'currency' },
  { slot: 'remittance' },
  { label: '账单截止日期', prop: 'accountPeriodDate', width: 120 }
]

export const vendorcolumns = [
  { label: '结算类型', prop: 'settlementTypeI18' },
  { label: '采购结算单', prop: 'purchaseSettlementCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '业务单据', prop: 'sourceCode' },
  { label: '采购单号', prop: 'purchaseOrderCode', width: 190 },
  { label: '税率', prop: 'taxRatio' },
  { label: '结算金额', prop: 'settlementAmount', width: 120 },
  { label: '待请款金额', prop: 'waitRequestFundsAmount', width: 110 },
  { label: '币种', prop: 'currency' },
  { slot: 'remittance' },
  { label: '账单截止日期', prop: 'accountPeriodDate', width: 120 }
]

export const collectcolumns = [
  { label: '结算类型', prop: 'settlementTypeI18' },
  { label: '采购结算单', prop: 'purchaseSettlementCode' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '业务单据', prop: 'sourceCode' },
  { label: '税率', prop: 'taxRatio' },
  { label: '结算金额', prop: 'purchaseAmount', width: 120 },
  { label: '待收款金额', prop: 'waitCollectAmount', width: 110 },
  { label: '是否开票', prop: 'ifInvoiceI18', width: 110 },
  { label: '开票金额', prop: 'invoiceAmount', width: 110 },
  { label: '不开票金额', prop: 'noInvoiceAmount', width: 110 },
  { label: '币种', prop: 'currency' },
  { slot: 'remittance' },
  { label: '账单截止日期', prop: 'accountPeriodDate', width: 120 }
]

export const tableData1 = [
  { taxRatio: 0, waitRequestFundsAmount: -100, requestFundsAmount: -100 },
  { taxRatio: 0, waitRequestFundsAmount: -150, requestFundsAmount: -150 },
  { taxRatio: 0, waitRequestFundsAmount: -150, requestFundsAmount: -150 },
  { taxRatio: 0, waitRequestFundsAmount: -300, requestFundsAmount: -300 },
  { taxRatio: 13, waitRequestFundsAmount: -390, requestFundsAmount: -390 },
  { taxRatio: 15, waitRequestFundsAmount: -1000, requestFundsAmount: -1000 }

]
// stone付款管理
export const columns = [
  { slot: 'monney' },
  { label: '请款类型', prop: 'collectPaymentTypeI18' },
  { label: '请款审批状态', prop: 'requestFundsApproveStatusI18', width: 130 },
  { label: '付款状态', prop: 'paymentStatusI18' },
  { label: '结算主体', prop: 'cargoOwnerName' },
  // { label: '请款类型', prop: 'requestFundsTypeI8' },
  { label: '供应商', prop: 'vendorName' },
  { label: '请款金额', prop: 'requestFundsAmount', width: 130 },
  { label: '币种', prop: 'currency' },
  { label: '请款审批时间', prop: 'requestFundsApproveDate', width: 170 },
  // { label: '待开票金额', prop: 'waitInvoiceAmount', width: 130 },
  // { slot: 'ticket' },
  // { label: '发票审核状态', prop: 'invoiceApproveStatusI18', width: 120 },
  { label: '待付款', prop: 'waitPaymentAmount', width: 120 },
  { slot: 'operate' }
]

export const expandColumns = [
  { label: '付款金额', prop: 'paymentAmount', width: 120 },
  { label: '付款时间', prop: 'paymentTime', width: 160 },
  { label: '登记人', prop: 'createByName', width: 110 },
  { label: '登记时间', prop: 'createTime', width: 170 },
  { slot: 'invoiceImg' },
  { label: '付款账户', prop: 'paymentAccount', width: 160 },
  { label: '收款账户', prop: 'receiveAccount', width: 160 },
  // { label: '付款方式', prop: 'createTime', width: 110 },
  { slot: 'paymentType', width: 120 },
  { label: '备注', prop: 'remarks', width: 170 }
]

export const ticketColumns = [
  { label: '发票号', prop: 'invoiceCode' },
  { label: '发票未税金额', prop: 'invoiceNoTaxAmount', width: 130 },
  { label: '发票税额', prop: 'invoiceTaxAmount' },
  { label: '税率%', prop: 'taxRate' },
  { label: '开票日期', prop: 'invoiceDate', width: 150 },
  { label: '登记日期', prop: 'createTime', width: 150 },
  { label: '登记人', prop: 'createByName' },
  { label: '审核时间', prop: 'invoiceApproveTime' },
  { label: '发票审核状态', prop: 'invoiceApprove', width: 130 },
  { slot: 'operate' }
]

export const jimPaymentDetailsColumns = [
  { label: '费用片段', prop: 'purchaseSettlementCode' },
  { label: '结算金额', prop: 'settlementAmount' },
  { label: '待请款金额', prop: 'waitRequestFundsAmount' },
  { slot: 'currency' },
  { label: '请款金额', prop: 'requestFundsAmount' }
]

export const jimTableColumns1 = [
  { label: '费用片段', prop: 'feeSnippet' },
  { label: '应付单', prop: 'payableNo' },
  { label: '应付金额', prop: 'paymentPendingAmount' },
  { label: '待请款金额', prop: 'requestPendingAmount' },
  { label: '币种', prop: 'currency' },
  { label: '请款金额', prop: 'requestAmount' }
]

export const jimTableColumns2 = [
  { label: '其中', prop: 'includes', width: 160 },
  { label: '开票金额', prop: 'invoiceAmount' },
  { label: '未税金额', prop: 'unTaxAmount' },
  { label: '增值税额', prop: 'vat' },
  { slot: 'same' }
]

export const importDetailsColumns = [
  { label: '备货渠道', prop: 'stockChannelName' },
  { label: 'Style', prop: 'style' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'Sku', prop: 'sku' },
  { label: '交货数量1', prop: 'expectPairs1', width: 100 },
  { label: '期望交期1', prop: 'expectDeliveryDate1', width: 90 },
  { label: '交货数量2', prop: 'expectPairs2', width: 100 },
  { label: '期望交期2', prop: 'expectDeliveryDate2', width: 90 },
  { label: '交货数量3', prop: 'expectPairs3', width: 100 },
  { label: '期望交期3', prop: 'expectDeliveryDate3', width: 90 },
  { label: '交货数量4', prop: 'expectPairs4', width: 100 },
  { label: '期望交期4', prop: 'expectDeliveryDate4', width: 90 }
]

export const orderContractcolumns = [
  { label: '合同编号', prop: 'purchaseOrderContractCode', width: 180 },
  { label: '采购主体', prop: 'cargoOwnerName', width: 100 },
  { label: '供应商', prop: 'vendorName', width: 90 },
  { label: '采购员', prop: 'purchasePerson', width: 100 },
  { label: '用印签署状态', prop: 'signStateI18', width: 120 },
  { label: '用印类别', prop: 'signPrintTypeI18', width: 120 },
  { label: '印章所属公司', prop: 'organizationName', width: 130 },
  { label: '印章类型', prop: 'signSealTypeI18' },
  { label: '签署类型', prop: 'signSignatureTypeI18' },
  { label: '用印日期', prop: 'signDate', width: 130 },
  { label: '用印发起人', prop: 'signSponsor', width: 130 },
  { label: '用印备注', prop: 'latestRemark', width: 130 },
  { label: '发起时间', prop: 'signTime', width: 100 },
  { label: '供应商上传盖章合同时间', prop: 'vendorUploadTime', width: 180 },
  { slot: 'number' },
  { label: '下载次数', prop: 'downloadNum' },
  { slot: 'imageURL' },
  { slot: 'name' },
  { slot: 'time' },
  { label: '供应商下载合同时间', prop: 'vendorDownloadTime', width: 180 },
  { slot: 'operate' }

]

export const frameContractcolumns = [
  { label: '合同编号', prop: 'purchaseFrameworkContractCode', width: 180 },
  { label: '采购主体', prop: 'cargoOwnerName', width: 130 },
  { label: '供应商', prop: 'vendorName' },
  { slot: 'number' },
  { label: '用印签署状态', prop: 'signStateI18', width: 120 },
  { label: '用印类别', prop: 'signPrintTypeI18', width: 120 },
  { label: '印章所属公司', prop: 'organizationName', width: 120 },
  { label: '印章类型', prop: 'signSealTypeI18' },
  { label: '签署类型', prop: 'signSignatureTypeI18' },
  { label: '用印日期', prop: 'signDate', width: 130 },
  { label: '用印发起人', prop: 'signSponsor', width: 130 },
  { label: '用印备注', prop: 'latestRemark', width: 130 },
  { label: '发起时间', prop: 'signTime', width: 100 },
  { label: '生效日期', prop: 'effectiveDate', width: 130 },
  { label: '失效日期', prop: 'expiryDate', width: 130 },
  { label: '签定日期', prop: 'signingDate', width: 130 },
  { slot: 'name' },
  { slot: 'time' },
  { slot: 'operate' }
]

export const frameContractDetailscolumns = [
  { label: '合同编号', prop: 'purchaseOrderContractCode', width: 220 },
  { label: '创建人', prop: 'createByName' },
  { label: '创建时间', prop: 'createTime', width: 200 }
]

export const contractPoDetails = [
  { label: '采购单号', prop: 'purchaseOrderCode' }
]

export const diffColumns = [
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: 'SKU', prop: 'sku' },
  { label: '税率%', prop: 'taxRatio' },
  { label: '规格型号', prop: 'specification' },
  { label: 'FNSKU', prop: 'fnsku' },
  { label: 'UPC', prop: 'upc' },
  { label: '差异金额', prop: 'reconcileAmount', width: 140 },
  { label: '备注', prop: 'modifyPriceRemark' }
]

export const returnColumns = [
  { label: '暂估应付单', prop: 'estimationPayableCode' },
  { label: '采购单号', prop: 'purchaseOrderCode' },
  { label: '采购主体', prop: 'cargoOwnerName' },
  { label: '供应商', prop: 'vendorName' },
  { label: '付款类型', prop: 'paymentTypeI18' },
  { label: '单据金额', prop: 'totalPrice' }
]
export const invoiceColumns = [
  { slot: 'collectMonth' },
  { label: '结算主体', prop: 'cargoOwnerName', width: 160 },
  { label: '供应商', prop: 'vendorName', width: 160 },
  { label: '发票数量', prop: 'invoicePairs' },
  { label: '开票金额', prop: 'registerAmount' },
  { label: '开票税额', prop: 'taxRegisterAmount' },
  { label: '币种', prop: 'currency' },
  { label: '开票税率(%)', prop: 'taxRatio' },
  { label: '录入发票金额', prop: 'invoiceRegisterAmount', width: 150 },
  // { label: '开票状态', prop: 'status' },
  { slot: 'inStatus' },
  { label: '开票登记日期', prop: 'createTime', width: 180 },
  { label: '审核日期', prop: 'invoiceApproveTime', width: 180 },
  // { label: '操作人', prop: 'createByName' },
  { slot: 'operateName' },
  { slot: 'operate' }
]

export const shoesBatchDetailsColumns = [
  { label: 'PO订单', prop: 'purchaseOrderCode' },
  { label: '鞋印批次号', prop: 'shoesBatchNo' }
]

export const planDetailsColumns = [
  { label: 'PO订单', prop: 'purchaseOrderCode' },
  { label: '计划评审时间', prop: 'planDeliveryDate' }
]

export const deliveryDetailsColumns = [
  { label: 'PO订单', prop: 'purchaseOrderCode' },
  { label: '供应商交期日期', prop: 'deliveryFeedback' },
  { label: '交期备注', prop: 'deliveryFeedbackRemark' },
  { label: '未准交原因', prop: 'deliveryDelayI18' },
  { label: 'LT不达标原因', prop: 'substandardI18' }
]

export const tableColumns = [
  { label: 'BU', prop: 'bu' },
  { label: 'Style', prop: 'style' },
  { label: '平台', prop: 'platform' },
  { label: '站点', prop: 'site' },
  { label: 'Style运营负责人', prop: '1', width: 130 },
  { label: 'BU/市场负责人', prop: '2', width: 130 },
  { label: 'BG运营负责人', prop: '3', width: 120 },
  { label: 'Style计划负责人', prop: '4', width: 130 },
  { label: 'BU计划负责人', prop: '5', width: 120 },
  { label: 'BG计划负责人', prop: '6', width: 120 },
  { label: 'PJM', prop: '7' }
]

export const supplementColumns = [
  { label: '协议编号', prop: 'agreementCode', width: 220 },
  { label: '协议类型', prop: 'agreementTypeI18' },
  { label: '关联合同类型', prop: 'contractTypeI18', width: 130 },
  { label: '合同编号', prop: 'contractCode', width: 180 },
  { label: '协议主体', prop: 'cargoOwnerName', width: 130 },
  { label: '供应商', prop: 'vendorName', width: 130 },
  { slot: 'number' },
  { label: '状态', prop: 'agreementStateI18' },
  { label: '用印类别', prop: 'signPrintTypeI18' },
  { label: '印章所属公司', prop: 'organizationName', width: 130 },
  { label: '印章类型', prop: 'signSealTypeI18' },
  { label: '签署类型', prop: 'signSignatureTypeI18' },
  { label: '用印日期', prop: 'signDate', width: 130 },
  { label: '用印发起人', prop: 'signSponsor', width: 120 },
  { label: '发起时间', prop: 'signTime', width: 100 },
  { label: '用印备注', prop: 'remark', width: 100 },
  { slot: 'operate' }
]

export const detailsColumns = [
  { label: 'BU', prop: 'bu' },
  { label: '平台', prop: 'platform' },
  { label: '站点', prop: 'site' },
  { label: 'BU/市场负责人', prop: 'buOperationPrincipal' },
  { label: 'BG运营负责人', prop: 'bgOperationPrincipal' },
  { label: 'Style计划负责人', prop: 'stylePlanPrincipal' },
  { label: 'BU计划负责人', prop: 'buPlanPrincipal' },
  { label: 'BG计划负责人', prop: 'bgPlanPrincipal' },
  { label: 'PJM', prop: 'pjm' }
]

export const wholeColumns = [
  { slot: 'adjustpo' },
  { slot: 'po' },
  { label: '供应商', prop: 'vendorName' },
  { label: '状态', prop: 'billStatusI18' },
  { slot: 'changePriceAgreementCode' },
  { label: '调价发起时间', prop: 'changePriceLaunchTime', width: 160 },
  { label: '生效时间', prop: 'effectiveTime' },
  { label: '成本工程师', prop: 'costEngineerName' },
  { slot: 'operate' }
]

export const detailColumns = [
  { slot: 'adjustpo' },
  { label: '状态', prop: 'billStatusI18' },
  { label: 'Style', prop: 'style' },
  { label: '采购订单号', prop: 'purchaseOrderCode', width: 170 },
  { label: '供应商', prop: 'vendorName' },
  { label: '规格型号', prop: 'specificationModel' },
  { label: '面料材质', prop: 'fabricMaterial' },
  { label: '底料材质', prop: 'baseMaterial' },
  { label: '币种', prop: 'currencyCode' },
  { label: '现单价（含税）', prop: 'changeAfterHaveTaxPrice' },
  { label: '原单价（含税）', prop: 'changeBeforeHaveTaxPrice' },
  { label: '现单价（不含税）', prop: 'changeAfterNoTaxPrice' },
  { label: '原单价（不含税）', prop: 'changeBeforeNoTaxPrice' },
  { label: '调整幅度', prop: 'adjustmentDesc', width: 130 },
  { label: '生效时间', prop: 'effectiveTime', width: 160 }
]

export const importAdjustDetailsColumns = [
  { label: '采购订单号', prop: 'purchaseOrderCode', width: 170 },
  { label: '供应商', prop: 'vendorName' },
  { label: 'Style', prop: 'style' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: '修改后单价（含税）', prop: 'changeAfterHaveTaxPrice' },
  { label: '调价原因', prop: 'changePriceReason' },
  { label: '备注', prop: 'remark' }
]

export const addAdjustcolumns = [
  { label: '采购订单号', prop: 'purchaseOrderCode', width: 200 },
  { label: '币种', prop: 'currencyCode' },
  { slot: 'adjustReason' },
  { slot: 'remark' }
]

export const expandAdjustColumns = [
  { label: '规格型号', prop: 'specificationModel' },
  { slot: 'afterAdjust' },
  { label: '调整前单价（含税）', prop: 'changeBeforeHaveTaxPrice', width: 160 },
  { label: '调整幅度', prop: 'changeRange' },
  { label: '币种', prop: 'currencyCode' }
]

export const eidtContractColumns = [
  { label: '采购订单', prop: 'purchaseOrderCode' },
  { label: '采购订单合同', prop: 'purchaseOrderContractCode', width: 170 },
  { label: '调价协议编号', prop: 'changePriceAgreementCode', width: 170 },
  { slot: 'operate' }
]

export const recordColumns = [
  { label: '操作人', prop: 'createByName' },
  { label: '操作时间', prop: 'createTime' },
  { label: '同步完成时间', prop: 'modifyTime' },
  { label: '同步状态', prop: 'statusI18' },
  { label: '同步类型', prop: 'typeI18' },
  { label: '同步信息', prop: 'msg' }
]

export const respDetailsColumns = [
  { label: 'BU', prop: 'bu' },
  { label: 'Style', prop: 'style' },
  { label: '平台', prop: 'platform' },
  { label: '站点', prop: 'site' },
  { label: 'Style运营负责人', prop: 'styleOperationPrincipal' },
  { label: 'BU/市场负责人', prop: 'buOperationPrincipal' },
  { label: 'BG运营负责人', prop: 'bgOperationPrincipal' },
  { label: 'Style计划负责人', prop: 'stylePlanPrincipal' },
  { label: 'BU计划负责人', prop: 'buPlanPrincipal' },
  { label: 'BG计划负责人', prop: 'bgPlanPrincipal' },
  { label: 'PJM', prop: 'pjm' }
]

export const vendorComponentColumns = [
  { slot: 'payNo', width: 160 },
  { label: '供应商', prop: 'vendorName' },
  // { label: '采购主体', prop: 'cargoOwnerName' },
  { label: '赔偿类型', prop: 'compensationTypeName' },
  { label: '业务类型', prop: 'businessTypeName' },
  { label: '赔偿原因', prop: 'reasonName', width: 120 },
  { label: '币种', prop: 'currencyCode' },
  { label: '金额', prop: 'amount' },
  { label: '状态', prop: 'stateName' },
  { label: '采购员', prop: 'purchaseName' },
  { label: '创建人', prop: 'createByName' },
  { label: '创建时间', prop: 'createTime', width: 160 },
  { label: '生效时间', prop: 'effectiveTime', width: 160 }
]

export const deliveryConfirmationColumns = [
  { label: '采购订单', prop: 'purchaseOrderCode', width: 160 },
  { label: '供应商', prop: 'createByName', width: 120 },
  { label: '下单日期', prop: 'processProduceTime', width: 100 },
  { label: '计划评审到货日期', prop: 'planDeliveryDate', width: 100 },
  { label: '操作时间', prop: 'createTime', width: 100 },
  { label: '供应商反馈交货日期', prop: 'deliveryDate', width: 100 },
  { label: '延期原因类型', slot: 'deliveryDelayI18', width: 100 },
  { label: '交期备注', slot: 'deliveryRemark' },
  { slot: 'purchaseDeliveryDate' },
  { slot: 'purchaseDeliveryDelay' },
  { slot: 'purchaseDeliveryRemark' }
]

export const deliveryConfirmColumns = [
  { label: '采购订单', prop: 'purchaseOrderCode' },
  { label: '供应商', prop: 'createByName' },
  { label: '下单日期', prop: 'processProduceTime' },
  { label: '操作时间', prop: 'createTime' },
  { label: '供应商反馈交货日期', prop: 'deliveryDate' },
  { label: '延期原因类型', prop: 'deliveryDelayI18' },
  { label: '交期备注', prop: 'deliveryRemark' },
  { label: '采购反馈到货日期', prop: 'purchaseDeliveryDate' },
  { label: '未准交原因', prop: 'purchaseDeliveryDelay' },
  { label: '交期备注', prop: 'purchaseDeliveryRemark' },
  { label: '是否通过', prop: 'confirmStatusI18' }
]

export const confirmComponentColumns = [
  { label: '赔偿单号', prop: 'compensationNo' },
  { label: '供应商', prop: 'vendorName' },
  // { label: '采购主体', prop: 'cargoOwnerName' },
  { label: '赔偿类型', prop: 'compensationTypeName' },
  { label: '业务类型', prop: 'businessTypeName' },
  { label: '赔偿原因', prop: 'reasonName', width: 120 },
  { label: '状态', prop: 'stateName' },
  { label: '币种', prop: 'currencyCode' },
  { label: '原金额', prop: 'originAmount' },
  { slot: 'confirmAmount' },
  { slot: 'remark' },
  { label: '创建人', prop: 'createByName' },
  { label: '创建时间', prop: 'createTime', width: 160 },
  { slot: 'attachmentfile' },
  { slot: 'emailState' },
  { slot: 'vendorImg', width: 160 }
]

export const templateTableColumns = [
  { label: '模板应用场景', prop: 'scenario' },
  { label: '模板类型', prop: 'templateName' },
  { slot: 'operate' }
]

export const VenndorConfirmColumns = [
  { label: '采购订单', prop: 'purchaseOrderCode' },
  { label: '需求交货日期', prop: 'displayEstiDeliveryTime' }
]

